import Image from 'next/image'
import { NextSeo } from 'next-seo'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Logo } from '@starchive/share/components/logo'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

export default function ErrorPage() {
  return (
    <>
      <NextSeo title='Page not found' />

      <div className='w-screen h-screen'>
        <Image
          src='/images/404.jpeg'
          layout='fill'
          objectFit='cover'
          className='opacity-25'
        />

        <div className='container flex items-center h-full mx-auto'>
          <div className='relative flex flex-col p-10 nightwind-prevent rounded-xl'>
            <div className='flex items-center font-bold text-8xl'>
              <span className='text-white nightwind-prevent'>4</span>
              <Logo isExtended={false} size={128} color='white' />
              <span className='text-white nightwind-prevent'>4</span>
            </div>

            <p className='text-2xl text-white nightwind-prevent'>
              The page you were looking for doesn't seem to exist.
            </p>

            <button
              style={{
                backgroundImage:
                  'linear-gradient(145deg,#814098 0%,#c71c8d 100%)',
                color: 'white',
              }}
              className='w-48 gap-2 mt-10 btn btn-solid btn-blue btn-lg glow-on-hover'
              onClick={() => {
                window.open(
                  'https://starchive.io',
                  '_blank',
                  'noopener noreferrer'
                )
              }}>
              Meet Starchive <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
